import React, { useState } from 'react';
import links from './links';
import './App.css';

const slug = link => link.toLowerCase()
  .replace(/\s+/g, '-')
  .replace(/[^\w-]+/g, '').trim();

const href = link => `https://${slug(link)}.blinkux.dev`;

const match = (link, search) => {
  return (link.tags || []).some(t => t.toLowerCase().includes(search.toLowerCase()))
    || link.name.toLowerCase().includes(search.toLowerCase())
    || (link.subtitle || '').toLowerCase().includes(search.toLowerCase());
}

// Edit ./links.js to add new links
const App = () => {
  const [search, setSearch] = useState('');

  const filteredLinks = links
    .filter(link =>
      search.length
        ? match(link, search)
        : true
      );

  return (
    <div className="App">
      {
        filteredLinks.length
          ? <ul>{
            filteredLinks
              .map(link =>
                <li key={ link.name }>
                  <a target="_blank" rel="noopener noreferrer" href={ link.href || href(link.name) }>
                    <span>
                      <div className="name">{ link.name.split(' ').map(el => <p key={ el }>{ el }</p>) }</div>
                      { link.subtitle && <p className="subtitle">{ link.subtitle }</p> }
                      { link.tags.length && <p className="tags">({ link.tags.join(', ') })</p> }
                    </span>
                  </a>
                </li>
              )
            }
          </ul>
          : <div className="nothing">
              <p>Nothing found matching that search!</p>
            </div>
      }
      <input
        type="text"
        value={ search }
        placeholder="Search projects..."
        onChange={ ({ target }) => setSearch(target.value) }
      />

    </div>
  );
}

export default App;
