// Each link has:
// - name (required)
// - subtitle (encouraged) - Technical description of app.
// - href (optional) - In absence of link, one will be generated automatically from the name.
//                     Make sure to subdomain the app at the right generated location.
// - tags (optional) - Array of tags for searching through projects.
export default [
  {
    name: 'SumoLogic',
    subtitle: 'B2B Lead Generation Website',
    href: 'https://www.sumologic.com',
    tags: ['Craft', 'CMS', 'React', 'Responsive', 'AWS', 'Localization']
  },
  {
    name: 'Tetrift',
    subtitle: 'Basic JavaScript Game',
    tags: ['Game', 'JavaScript']
  },
  {
    name: 'Acubed by Airbus',
    subtitle: 'Blazing fast and SEO-friendly Gatsby app for the Airbus innovation center',
    tags: ['Gatsby', 'SEO', 'React', 'Contentful', 'CMS']
  },
  {
    name: 'World Innovation Lab',
    subtitle: 'Localized CMS-Powered Single-Page React App',
    href: 'https://wilab.com',
    tags: ['React', 'Animation', 'CMS', 'Contentful', 'Localization', 'Responsive']
  },
  {
    name: 'Noon Home',
    subtitle: 'Custom E-Commerce App',
    tags: ['React', 'E-Commerce', 'Shopify', 'Zendesk', 'AWS Cognito', 'APIs'],
    href: 'https://noonhome.com'
  },
  {
    name: 'Amazon Publishing',
    subtitle: 'B2B Brochure Website',
    tags: ['Handlebars JS', 'AWS', 'Responsive'],
    href: 'https://www.apub.com'
  },
  {
    name: 'Advanced Microgrid Solutions',
    subtitle: 'React Data Visualization, API Specification (SLOW)',
    tags: ['Data', 'Data Visualization', 'API', 'Design Library']
  },
  {
    name: 'Motimatic',
    subtitle: 'CMS-Powered Single-Page React App',
    href: 'http://motimatic.com',
    tags: ['Contentful', 'CMS', 'React', 'Animation', 'Responsive']
  },
  {
    name: 'Marcom Branded Portal',
    subtitle: 'Design System, Component Library',
    tags: ['Fabricator', 'Angular', 'Design Library'],
    href: 'https://usertest.com/j66j6rB3t3t3g2lYddasdf/'
  },
  {
    name: 'This Year in Books',
    subtitle: 'Campaign Landing Page',
    tags: ['Handlebars JS', 'API', 'Animation'],
    href: 'https://www.amazon.com/article/this-year-in-books'
  },
  {
    name: 'Performance Phenomics',
    subtitle: 'React App, AWS, Secure Data API',
    tags: ['React', 'AWS', 'Serverless', 'Database', 'API', 'Cloud', 'Design Library', 'Responsive']
  },
  { name: 'Holiday 2016', subtitle: 'Fun React App', tags: ['React'] },
  {
    name: 'Transcribble',
    subtitle: 'React Web App with Serverless API and Mobile Version',
    tags: ['React', 'AWS', 'Transcription', 'Mobile', 'API', 'Serverless', 'Cloud', 'React Native']
  },
  {
    name: 'World Book Day',
    subtitle: 'Campaign Landing Page',
    tags: ['Handlebars JS', 'JSON', 'API', 'Localization'],
    href: 'https://www.amazon.com/article/read-the-world'
  },
  {
    name: 'JD Power',
    subtitle: 'Angular App',
    tags: ['Angular', 'Responsive', 'Mobile First']
  },
  {
    name: 'Holiday 2017',
    subtitle: 'Animated React App',
    tags: ['React', 'Animation']
  },
  {
    name: 'KP Component Library',
    subtitle: 'Design System, Component Library',
    tags: ['Design Library']
  },
  {
    name: 'Marcom DAM',
    subtitle: 'React Screens w Component Library',
    tags: ['React', 'Screens', 'Component Library', 'Design System']
  },
  {
    name: 'Marcom DAM CL',
    subtitle: 'Component Library',
    tags: ['React', 'Component Library', 'Design System']
  },
  {
    name: 'Acubed by Airbus',
    subtitle: "Site for Airbus's Innovation Lab",
    tags: ['Gatsby', 'CMS', 'Contentful', 'React', 'SEO', 'Animation']
  }
];
